import GoogleMapReact from 'google-map-react';
import React, { Component } from 'react';
import { FiChevronUp, FiHeadphones, FiMail, FiMapPin } from 'react-icons/fi';
import ScrollToTop from 'react-scroll-up';
import PageHelmet from '../component/common/Helmet';
import Footer from '../component/footer/Footer';
import Header from '../component/header/Header';
import BrandTwo from '../component/brand/BrandTwo';
import mapStyles from '../config/mapStyles.json';

const AnyReactComponent = ({ text }) => (
  <div>
    <img
      src="/assets/images/logo/logo.svg"
      style={{
        backgroundColor: '#000',
        borderRadius: 20,
        width: 40,
        height: 40,
      }}
    />
  </div>
);

class Contact extends Component {
  static defaultProps = {
    center: {
      lat: 59.3323679,
      lng: 18.0430513,
    },
    zoom: 13,
  };

  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Kontakta oss" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logo="light"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Kontakta oss</h2>
                  <p>
                    Kom i kontakt med oss för att boka ett förutsättningslöst
                    möte.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Contact Top Area  */}
        <div className="rn-contact-top-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title">Telefonnummer</h4>
                    <p>
                      <a href="tel:+46735067100">+46 73 506 7100</a>
                    </p>
                    <p>
                      <a href="tel:+46735067100">+46 73 506 7100</a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">Mailadress</h4>
                    <p>
                      <a href="mailto:hello@appuniverse.com">
                        hello@appuniverse.com
                      </a>
                    </p>
                    <p>
                      <a href="mailto:support@appuniverse.com">
                        support@appuniverse.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">Plats</h4>
                    <p>
                      Mäster Samuelsgatan 36 (Epicenter) <br /> Stockholm 111 57
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}

        {/* Start Contact Map  */}
        <div className="rn-contact-map-area position-relative">
          <div style={{ height: '650px', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyBBUqpfEzrOwmu7DH_qH3p24YINg-hGMvI',
                language: 'se',
                region: 'se',
              }}
              defaultCenter={this.props.center}
              defaultZoom={this.props.zoom}
              options={{
                styles: mapStyles,
              }}
            >
              <AnyReactComponent
                lat={59.3339921}
                lng={18.0663448}
                text="App Universe"
              />
            </GoogleMapReact>
          </div>
        </div>
        {/* End Contact Map  */}

        {/* Start Brand Area */}
        <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Contact;
