import React, { Component } from "react";

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          <li>
            <img src="/assets/images/brand/swedbank.svg" alt="Logo Images" />
          </li>
          <li>
            <img
              src="/assets/images/brand/appleg.svg"
              alt="Logo Images"
              height={70}
            />
          </li>
          <li>
            <img src="/assets/images/brand/session.svg" alt="Logo Images" />
          </li>
          <li>
            <img
              src="/assets/images/brand/cgi.svg"
              alt="Logo Images"
              height={42}
            />
          </li>
          <li>
            <img
              src="/assets/images/brand/mit.svg"
              alt="Logo Images"
              height={60}
            />
          </li>
          <li>
            <img
              src="/assets/images/brand/sca.svg"
              alt="Logo Images"
              height={35}
            />
          </li>
          <li>
            <img
              src="/assets/images/brand/acando.svg"
              alt="Logo Images"
              height={80}
            />
          </li>
          <li>
            <img
              src="/assets/images/brand/flarie.svg"
              alt="Logo Images"
              height={30}
            />
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;
